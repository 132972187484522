.postnl {

  .stamp-accordion-item {
    inline-size: 100%;
    border-bottom: 1px solid #d4d9e3;
  }

  .stamp-accordion-item__header {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
  }

  .stamp-accordion-item__button {
    all: inherit;
    display: flex;
    align-items: center;
    inline-size: 100%;
    padding: 16px 0;
    cursor: pointer;
    gap: 16px;

    &:hover {
      .stamp-accordion-item__title {
        color: #3440b6;
        text-decoration: underline;
      }
    }
  }

  .stamp-accordion-item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3440b6;
    inline-size: 24px;
    block-size: 24px;
  }

  .stamp-accordion-item__icon--end {
    margin-right: 16px;
    margin-left: auto;
  }

  .stamp-accordion-item__panel {
    padding: 0 0 16px 0;
  }

}
