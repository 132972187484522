/**
 * Styles copied and extended from:
 * - https://www.postnl.nl/versturen/brief-of-kaart-versturen/
 * - https://www.postnl.nl/versturen/pakket-versturen/
 * - https://www.postnl.nl/cookie-verklaring/
 */

.postnl {
  display: flex;
  min-height: 100%;
  flex-direction: column;

  &.stamp-theme-provider {
    color: #27324c;
    font-family: PostNL, sans-serif;
    font-weight: 300;
    line-height: 1.5;
    font-size: 16px;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
  }

  * {
    padding: 0;
    margin: 0;
  }

  h1 {
    color: #001a73;
    font-size: 2.25rem;
    line-height: 1.111;
    font-weight: 300;
  }

  h2 {
    color: #ed7000;
    font-size: 1.75rem;
    line-height: 1.286;
    font-weight: 400;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.333;
    font-weight: 400;
  }

  a {
    &:not([class]) {
      color: #3440b6;
      &:hover {
        text-decoration-thickness: 2px;
      }
      &:focus {
        outline: 0;
      }
    }
  }

  .header__universal {
    display: block;
    background: #fff;
    padding: 12px 24px;    // From .header-mobile
  }

  .header__bottom-left {  // Simplified version of header-mobile__bottom-left and header-desktop__bottom-left
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    align-items: center;

    @media (min-width: 768px) {
      grid-template-columns: 0 1fr 1fr;
    }
  }

  .header__universal--homelink {
    justify-self: center;

    img {
      height: 40px;
      width: 40px;
    }

    @media (min-width: 768px) { // min-width changed to match breakpoint
      justify-self: start;

      img {
        height: 88px;
        width: 88px;
      }
    }
  }

  .header__universal--login {
    justify-self: end;
  }

  main {
    flex: 1  // Push footer to the document bottom
  }

  main > .outer {
    margin: 2.5rem auto;

    @media (min-width: 768px) { // min-width changed to match breakpoint
        margin: 5.5rem auto;
    }

    @media (max-width: 768px) { // max-width changed to match breakpoint
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .outer--light-blue-tone {
    background-color: #f1f2f9;
    padding: 24px 0; // To avoid use of pci-how-to classes

    @media (min-width: 48em) {
      padding: 56px 0;
    }
  }

  .inner {
    max-width: 69rem;
    padding: 0 1.5rem;
    width: auto;
    margin: 0 auto;

    @media (min-width: 42.5em) {
      padding: 0 2.5rem;
    }
    @media (min-width: 55em) {
      padding: 0 5.5rem;
    }
    @media (min-width: 80em) {
      padding: 0;
    }
  }

  .inner-narrow {
    max-width: 33.25rem;

    @media (min-width: 35.25em) {
      margin: 0 auto;
      padding: 0;
    }
  }

  .article h1 + .article__intro {
    margin-block-start: 24px;
  }

  .intro {
    font-size: 1.1875rem;
    line-height: 1.475;
  }

  .article__intro {
    color: #66728a;
    margin-block-end: 40px;
  }

  .rich-text {
    margin-bottom: 24px;
  }

  .rich-text ul {
    padding-left: 1.5rem;
    margin-left: 0;
    list-style: initial;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .rich-text li + li {
    margin-top: 8px;
  }

  .rich-text h3 {
    margin-bottom: 8px;
  }

  .rich-text p {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .rich-text.intro {
    font-size: 1.3125rem;
    line-height: 1.52;
    color: #66728a;
    text-align: center;
    max-width: 33.25rem;
  }

  .rich-text.columned-layout__intro.intro {
    margin-block-start: 8px;
    margin-block-end: 24px;

    @media (min-width: 42.5em) {
      margin-block-end: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .pci-banner {
    video {
      display: block;
      width: 100%;
    }
  }

  .pci-banner__content-container {
    display: flow-root;   // Added to adjust element height to floating content
    padding: 24px 24px 24px;
  }

  .pci-banner__title {
    color: #001a73;
    font-size: 2.25rem;
    line-height: 1.111;
    font-weight: 300;

    @media (min-width: 48em) {
      font-size: 3rem;
    }
  }

  .pci-banner__subtitle {
    font-size: 1.5rem;
    line-height: 1.3333;
    font-weight: 400;
    color: #ed7000;
    margin-bottom: 0;
    display: block;
    margin-top: 8px;

  }

  .pci-banner__image-container {
    position: relative;
    margin-top: 24px;
    margin-left: -24px;
    margin-right: -24px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  .pci-banner__body {
    font-size: 19px;
    font-weight: 300;
    color: #66728a;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .pci-cta__container {
    margin-top: 32px;
  }

  .pci-spark {
    display: none;  // replaces pci-banner__image hide-mobile
    background-color: #ff8d00;
    position: absolute;
    height: 40px;
    width: 40px;
    top: -20px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 50%;
      position: absolute;
      background-color: #fbc54f;
    }

    @media (min-width: 768px) {
      display: block; // replaces pci-banner__image hide-mobile
    }
  }

  .pci-spark--left {
    left: -20px;
    border-radius: 0 8px 8px 8px;

    &:after {
      right: 0;
    }
  }

  .pci-usp-block {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) { // max-width changed to match breakpoint
      align-items: center;
    }
  }

  .pci-usp-block__title {
    color: #27324c;
  }

  .pci-usp-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    width: 100%;

    @media (min-width: 768px) { // max-width changed to match breakpoint
      flex-direction: row;
    }
  }

  .pci-usp-item {
    list-style: none;
    flex: 1 1;
  }

  .pci-usp-item__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .pci-usp-item__image-container {
    width: 80px;
    height: 80px;
    flex-shrink: 0;

    @media (min-width: 48em) {
      width: 138px;
      height: 138px;
    }
  }

  .pci-usp-item__title {
    color: #ed7000;
  }

  .pci-usp-item__step { // Loose interpretation of pci-how-to-item__step
    font-size: 1.1875rem;
    font-weight: 400;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    margin-right: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ed7000;
    border-radius: 4px 4px 0 4px;
  }

  .pci-picture {
    width: 100%;
    height: 100%;
  }

  .pci-popover__trigger {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 1rem;
    color: #3440b6;
  }

  .pci-popover__trigger .icon {
    height: 1rem;
  }

  .pci-popover__trigger[data-state=open] .icon {
    rotate: 180deg;
  }

  .stamp-button {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    inline-size: 100%;
    max-inline-size: 100%;
    cursor: pointer;
    padding-block: 8px;
    padding-inline: 24px;
    border-style: solid;
    border-width: 1px;
    font-family: PostNL, sans-serif;
    font-weight: 500;
    line-height: 1.5;
    font-size: 16px;
    letter-spacing: 0;
    text-decoration: none;

    @media (min-width: 768px) { // min-width changed to match breakpoint
      inline-size: auto;
    }
  }

  .stamp-button--variant-primary {
    color: #FFF;
    background-color: #3440b6;
    border-color: #ffffff00;
    border-radius: 24px;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline-color: #3440b6;
    }

    &:focus-visible {
      outline-style: solid;
      outline-width: 2px;
      outline-offset: 1px;
    }

    &:active {
      background-color: #001a73;
      text-decoration: underline;
    }
  }

  .stamp-icon {
    display: flex;
  }

  .stamp-icon--size-s {
    inline-size: 16px;
    block-size: 16px;
  }

  .footer-global {
    background-color: #66728a;
    padding: 24px 0 16px;
    color: #fff;
    font-size: 0.875rem;

    @media (min-width: 48em) {
      padding: 24px 0;
    }
  }

  .footer-global__container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 24px;
    align-items: center;

    &>span {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 1 100%;
      margin-bottom: 8px;

      @media (min-width: 768px) {  // min-width changed to match breakpoint
        flex: 1 1 auto;
        margin-bottom: 0;
      }
    }
  }

  .pci-cta {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 24px;
    justify-content: center;
    color: #fff;
    background-color: #3440b6;
    border-radius: 20px;
    border: 1px solid #3440b6;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
    text-decoration: none;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .pci-cta__global-footer-link {
    background: none;
    border: none;
    padding: 0;
    display: inline-flex;
    width: auto;
    border-radius: 2px;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  .pci-faq-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-block: 24px;
    max-width: 33.25rem;
    margin: 0 auto;
    width: 100%;
  }

  .pci-faq-list--collapsible {
    gap: 0;
  }

  .columned-layout__title {
    text-align: center;
    color: #27324c;
  }

  .columned-layout__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 24px;
    overflow: hidden;

    @media (min-width: 42.5rem) {
      flex-direction: row;
      gap: 2.5rem;
      margin-top: 40px;
    }
  }

}
