@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.video-wrapper {
  position: relative;

  .video-controls {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .video-play-button {
      color: white;
      border: none;
      background: none;
      cursor: pointer;
      outline: none;
      animation: pulse 2s infinite;

      svg {
        width: 80px;
        height: 80px;
        fill: #27324c;
      }
    }
  }

}
