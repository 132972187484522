.land {

  @media (min-width: 768px) {
    .left {
      float: left;
      width: 50%;
      padding-right: 40px;
    }

    .right {
      float: right;
      width: 50%;
    }
  }

}
